// Import our CSS
import styles from '../css/app.scss';

// Import polyfills for IE 11
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import (/* webpackChunkName: "jqueryscripts" */ './scripts.js');
import (/* webpackChunkName: "imageboss" */ './utils/imageboss.js');
import browserUpdate from 'browser-update';

// App main
const main = async () => {
    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');
    // Create our vue instance
    const vm = new Vue({
        el: "main",
        components: {
            'spin': () => import(/* webpackChunkName: "spinwidget" */ './modules/Spin.vue'),
            'category': () => import(/* webpackChunkName: "categories" */ './modules/ProductCategory.vue'),
            'search': () => import(/* webpackChunkName: "search" */ './modules/Search.vue'),
            'facilities': () => import(/* webpackChunkName: "facilities" */ './modules/Facilities.vue'),
            'photoGallery': () => import(/* webpackChunkName: "photogallery" */ './modules/PhotoGallery.vue'),
            'whereToBuy': () => import(/* webpackChunkName: "whereToBuy" */ './modules/WhereToBuy.vue'),
            'dynamicDocuments': () => import(/* webpackChunkName: "photogallery" */ './modules/dynamicDocuments.vue'),
        }
    });

    return vm;
};

// Execute async function
main().then( (vm) => {
    // Reinitialize formie
    // This fixes an issue where formie was added before vue initialized
    // but after vue initializes, formie is removed.
    // So we just reinitialize it after Vue.
    if("undefined"!=typeof Formie){
        let forms = document.querySelectorAll('form[data-submit-method="ajax"]');

        if (forms.length) {
            window.Formie.initForms();
        }
    }
    // Old browser detect
    browserUpdate({
        required: {
            e:-3,
            f:-3,
            o:-3,
            s:-3,
            c:-5,
            samsung: -5
        },
        reminder: 24,
        test:false,
        insecure:true,
        shift_page_down: false
    });
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
